import {createElement, StrictMode, FC, useMemo, Fragment} from 'react';
import {Router} from 'react-router-dom';
import {history} from '@netvision/lib-history';
import {GlobalObserver} from './GlobalObserver';
import {GlobalEventBus} from './GlobalEventBus';
import createRenderer from './Renderer';
import * as RenderModel from './RenderModel';

interface Props {
  widgetTree: RenderModel.Node | RenderModel.Node[];
  decorators: RenderModel.Decorator[];
}

type SimplifiedNode = RenderModel.Node & {
  isObservable?: boolean;
  props?: Record<string, any>;
  areas?: Record<string, any>[];
  children?: Record<string, any>[];
};

function addObserverToWidgetTree(node: SimplifiedNode) {
  const stack: Record<string, any>[] = [node];
  const observer = new GlobalObserver();
  const eventBus = new GlobalEventBus();
  while (stack.length) {
    const currNode = stack.pop();
    if (currNode) {
      currNode.props = {
        ...(currNode.props || {}),
        observer,
        eventBus
      };
    }
    if (currNode?.areas?.length) {
      for (let i = 0; i < currNode.areas.length; i++) {
        stack.push(currNode.areas[i]);
      }
    }
    if (currNode?.children?.length) {
      for (let i = 0; i < currNode.children.length; i++) {
        stack.push(currNode.children[i]);
      }
    }
  }
}

export const Root: FC<Props> = ({widgetTree, decorators = []}) => {
  Array.isArray(widgetTree)
    ? widgetTree.forEach((tree) => addObserverToWidgetTree(tree))
    : addObserverToWidgetTree(widgetTree);
  const renderChildren = useMemo(() => createRenderer(decorators), [decorators]);
  let el = createElement(Fragment, undefined, 'Widget tree is not provided');
  if (widgetTree) {
    el = createElement(Fragment, undefined, renderChildren(widgetTree instanceof Array ? widgetTree : [widgetTree]));
  }
  return createElement(StrictMode, undefined, createElement(Router, {history}, el));
};
