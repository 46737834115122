import {GlobalTransmitter} from './GlobalTransmitter';
import {
  GlobalEventBus as IGlobalEventBus,
  PublishPayload,
  SubscribeHandler,
  Subscriber
} from '@netvision/lib-types-frontend';

export class GlobalEventBus extends GlobalTransmitter implements IGlobalEventBus {
  private subscribers = new Map<string, Subscriber>();

  protected checkSubscriber(id: string): void {
    if (!this.subscribers.has(id)) {
      throw new Error(`subscribers doesn't contain the entry with id "${id}"`);
    }
  }

  constructor() {
    super();
  }

  get subscribersMap() {
    return this.subscribers;
  }

  addUniqueSubscriberMap(): string {
    const id = this.uuid();
    this.subscribers.set(id, new Map());
    return id;
  }

  subscribe(id: string, eventName: string, callback: SubscribeHandler): void {
    this.checkSubscriber(id);
    const entryById = this.subscribers.get(id);
    if (entryById?.has(eventName)) {
      entryById.get(eventName)?.add(callback);
    } else {
      entryById?.set(eventName, new Set([callback]));
    }
  }

  unsubscribe(id: string, eventName: string, callback?: SubscribeHandler): void {
    this.checkSubscriber(id);
    const entryById = this.subscribers.get(id);
    if (entryById?.has(eventName)) {
      !callback ? entryById.delete(eventName) : entryById.get(eventName)?.delete(callback);
    }

    if (!entryById?.get(eventName)?.size) {
      entryById?.delete(eventName);
    }

    if (!entryById?.size) {
      this.subscribers.delete(id);
    }
  }

  notify<T>(id: string, eventName: string, payload: PublishPayload<T>): void {
    this.checkSubscriber(id);
    const entryById = this.subscribers.get(id);
    if (entryById?.has(eventName)) {
      entryById.get(eventName)?.forEach((handler) => handler(payload));
    }
  }
}
