import {GlobalObserver as IGlobalObserver} from '@netvision/lib-types-frontend';
import {GlobalTransmitter} from './GlobalTransmitter';

export class GlobalObserver extends GlobalTransmitter implements IGlobalObserver {
  private subscribers = new Map<string, Set<(payload: any) => void>>();

  protected checkSubscriber(id: string): void {
    if (!this.subscribers.has(id)) {
      throw new Error(`subscribers doesn't contain the entry with id "${id}"`);
    }
  }

  constructor() {
    super();
  }

  get subscribersMap() {
    return this.subscribers;
  }

  addUniqueSubscriberSet(): string {
    const id = this.uuid();
    this.subscribers.set(id, new Set());
    return id;
  }

  subscribe<T>(id: string, callback: (payload: T) => void): void {
    this.checkSubscriber(id);
    this.subscribers.get(id)?.add(callback);
  }

  unsubscribe<T>(id: string, callback?: (payload: T) => void): void {
    this.checkSubscriber(id);
    !callback ? this.subscribers.delete(id) : this.subscribers.get(id)?.delete(callback);

    if (!this.subscribers.get(id)?.size) {
      this.subscribers.delete(id);
    }
  }

  notify<T>(id: string, payload: T): void {
    this.checkSubscriber(id);
    this.subscribers.get(id)?.forEach((handler) => handler(payload));
  }
}
